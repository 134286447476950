<template>
  <div class="container has-background-white-ter is-fullheight">

    <div class="notification has-background-white has-text-centered">
      <h1 class="title">Login</h1>
      <br>

      <p>
        Login to manage your school account
      </p>

      <br>

      <div id="firebaseui-auth-container"></div>
    </div>
    <!-- /Notification -->
  </div>
  <!-- /Container -->

</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import store from '@/plugins/store';
import router from '@/plugins/router.js'

var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {

      var currentUser = authResult.user;
      if (currentUser.emailVerified) {
        store.commit("forceUpdateUserDetails");
        router.push("/interstitial")
      }
      else {
        router.push("/verify")
      }
      return false
    },
  },
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      clientId: "481815724594-pt6l204cbguq3mbo9ivpj6jlvtbb18bd.apps.googleusercontent.com",
      scopes: [
 //       //  'https://www.googleapis.com/auth/contacts.readonly',
 //       'https://www.googleapis.com/auth/classroom.push-notifications',
 //       'https://www.googleapis.com/auth/classroom.rosters.readonly',
 //       'https://www.googleapis.com/auth/classroom.coursework.students.readonly'
      ],
    },
    //firebase.auth.EmailAuthProvider.PROVIDER_ID,

    //firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
    },
    //firebase.auth.PhoneAuthProvider.PROVIDER_ID,



  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,

  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '<your-tos-url>',

  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign('<your-privacy-policy-url>');
  }
};

// Initialize the FirebaseUI Widget using Firebase.

export default {
  name: "auth",

  data() {
    return {

      ui: null

    }
  },



  mounted() {

    if (this.ui == null) {
      this.ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    // The start method will wait until the DOM is loaded.
    this.ui.start('#firebaseui-auth-container', uiConfig);
  },



};
</script>